import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subject } from 'rxjs'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { LoaderOptionsObject } from 'src/app/interfaces/loaderOptions'
import { Pagination, Resp } from 'src/app/interfaces/response'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { ConstantsService } from 'src/app/services/constants.service'
import { DataService } from './data.service'
import moment from 'moment'
import { HttpEventType } from '@angular/common/http'

@Component({
  selector: 'app-mentor-inspections',
  templateUrl: './mentor-inspections.component.html',
  styleUrls: ['./mentor-inspections.component.css']
})
export class MentorInspectionsComponent implements OnInit {
  downloadProgress = 0
  incrementalProgress = 0
  dataStatus = 'fetching'
  inspectionList: Array<any> = []
  selectedIndex: number
  pagination: Pagination<any>
  moment = moment
  dataForm: FormGroup
  selectedId: any
  searchMentorList: Array<any> = []
  mentorIds: Array<any> = []

  filters = {
    startDate: moment().subtract(3, 'months').toDate(),
    endDate: new Date(),
    page: 1,
    mentorIds: [],
    perPage: 10
  }
  breadCrum = [
    {
      link: '/admin/mentor-inspections',
      value: 'Mentors Inspections'
    }
  ]
  loaderOptions: LoaderOptionsObject = ConstantsService.createLoaderConfig(5, 7, 1)


  waiting: {
    search: boolean
    save: boolean
    userStatus: Array<any>
  }

  constructor(
    private ds: DataService,
    private fb: FormBuilder,
    public ui: UIHelpers,
    private alert: IAlertService,
    public cs: ConstantsService,
    public router: Router,
    private route: ActivatedRoute,
  ) {
    this.waiting = {
      search: false,
      save: false,
      userStatus: []
    }

    this.dataForm = this.fb.group({
      id: new FormControl(null),
      amount: new FormControl(null, [Validators.required]),
      description: new FormControl('', [Validators.required]),
    })

    this.route.queryParams.subscribe((params) => {
      if (params.page) {
        this.filters.page = params.page
      }
      if (params.perPage) {
        this.filters.perPage = params.perPage
      }
      if (params.startDate) {
        this.filters.startDate = params.startDate
      }
      if (params.endDate) {
        this.filters.endDate = params.endDate
      }
      if (params) {
        // this.search()
      }
    })
  }

  ngOnInit(): void {
    this.getMentors()
    // this.search()
  }

  setPagination(page: any) {
    this.filters.page = page
    this.router.navigate(['/admin/mentors-inspections'], {
      queryParams: this.filters,
      replaceUrl: true
    })
  }
  search(): void {
    this.waiting.search = true

    const paramsToSend: any = { ...this.filters }
    paramsToSend.startDate = moment(paramsToSend.startDate).format('YYYY-MM-DD')
    paramsToSend.endDate = moment(paramsToSend.endDate).format('YYYY-MM-DD')

    this.ds.getList(paramsToSend).subscribe((resp: any) => {
      this.waiting.search = false
      if (resp.success === true) {
        this.inspectionList = resp?.data?.data
        
        this.pagination = resp.data as Pagination<any>
        this.dataStatus = 'done'
      }
    })
  }

  get g() {
    return this.dataForm.controls
  }

  getMentors() {
    this.ds.getMentorList().subscribe((resp: any) => {
      this.searchMentorList = resp.data || []
    })
  }

  importExcel() {
    this.waiting.save = true
    const paramsToSend: any = { ...this.filters }
    paramsToSend.startDate = moment(paramsToSend.startDate).format('YYYY-MM-DD')
    paramsToSend.endDate = moment(paramsToSend.endDate).format('YYYY-MM-DD')
    this.sendCall(paramsToSend)
  }
  sendCall(data: any): void {
    this.downloadProgress = 0
    this.incrementalProgress = 0
    this.ds.downloadExcelFile(data).subscribe((resp: any) => {
      if (resp.type === HttpEventType.DownloadProgress) {
        this.downloadProgress = Math.round(resp.loaded * 100 / resp.total)
        this.incrementalProgress = this.downloadProgress
      } else if (resp.type === HttpEventType.Response) {
        const blob = new Blob([resp.body], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"
        })
        const downloadURL = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadURL
        link.download = 'Mentor inspections report'
        link.click()
        setTimeout(() => {
          this.waiting.save = false
        }, 50)
      }
    })
  }

}


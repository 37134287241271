<h1 class="title">Mentor Inspections</h1>
<app-breadcrums [data]="breadCrum"></app-breadcrums>
<div class="row">
  <div class="col-md-3 cg-input-group">
    <label>Mentors</label>
    <ng-select [(ngModel)]="filters.mentorIds" [multiple]="true" style="min-width: 250px" [clearable]="false"
      placeholder="Select Mentors">
      <ng-option *ngFor="let item of searchMentorList" [value]="item.id">{{item.name}}</ng-option>
    </ng-select>
  </div>
  <div class="col-md-3 cg-input-group">
    <label>Start Date</label>
    <input type="text" class="cg" [(ngModel)]="filters.startDate" bsDatepicker
      [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }"
      placeholder="Start Date" />
  </div>
  <div class="col-md-3 cg-input-group">
    <label>End Date</label>
    <input type="text" class="cg" [(ngModel)]="filters.endDate" bsDatepicker
      [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }"
      placeholder="End Date" />
  </div>
  <div class="col cg-input-group d-flex justify-content-between align-items-end">
    <div class="btn-group" dropdown>
      <button [disabled]="waiting.search" [ngClass]="waiting.search ? 'in-progress' : ''" type="button"
        class="cg secondary" (click)="filters.page = 1; search()">
        Search
        <span></span>
        <span></span>
        <span></span>
      </button>

      <button class="primary cg ms-1" [disabled]="waiting.save"
        (click)=" waiting.save ? 'in-progress' : '' ; importExcel();">
        Download
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  </div>
</div>
<!-- <div class="col-md-12" *ngIf="waiting.save">
  <div style="background-color: #ccc; width: 100%;">
    <div class="bar" [style.width.%]="incrementalProgress">{{incrementalProgress}}%</div>
  </div>
</div>
<div class="d-flex justify-content-center mt-4" *ngIf="dataStatus === 'fetching'">
  <div class="img-wrapper">
    <img src="assets/images/rolling-grey-50.svg" alt="">
  </div>
</div> -->
<div class="class-manager" *ngIf="dataStatus == 'done' && inspectionList?.length === 0">
  <app-no-data-found></app-no-data-found>
</div>
<div *ngIf="dataStatus == 'done' && inspectionList?.length>0">
  <div class="mt-4 mb-4" >
    <div class="box">
      <div class="table-responsive">
        <table class="cg primary">
          <thead class="sticky">
            <!-- <tr>
              <p><strong class="primary">Parallel: </strong>{{item?.parallel?.name | titlecase}}</p>
              <p *ngIf="item.balance >= 0"><strong class="primary">Balance: </strong>${{item?.parallel?.balance}}</p>
              <p *ngIf="item.balance < 0"><strong class="primary">Balance: </strong>-${{item?.parallel?.balance * -1}}</p>
            </tr> -->

            <tr>
              <th >Address</th>
              <th>Parallel Name</th>
              <th>Mentor Name</th>
              <th>Parallel Email</th>
              <th class="text-center">Booking Status</th>
              <th class="text-center">Inspection Date</th>
              <th class="text-center"> Time</th>
              <!-- <th>End Time</th> -->
              <th class="text-center">Inspection Status</th>
              
              <!-- <th style="width: 40%;">Description</th> -->
            </tr>
          </thead>
          <!-- <tbody *ngIf="dataStatus === 'fetching'" [appSkeletonTabelLoader]="loaderOptions"></tbody> -->
          <tbody>
            <tr *ngFor="let item of inspectionList; let i = index">
              <td >
                {{ item?.inspection?.address }}<br/>
                {{item?.inspection?.addressTwo}}<br/>
                {{item?.inspection?.city}},{{item?.inspection?.zipCode}},
              </td>
              <td>
                {{ item?.parallel?.name }}
              </td>
              <td >
                {{ item?.inspection?.mentor?.name }}
              </td>
              <td >
                {{ item?.parallel?.user?.email }}
              </td>
              <!-- <td class="text-center">
                {{ item?.status }}
              </td> -->

              <td class="text-center">
                <div *ngIf="item?.status === 'rejected' || item?.status === 'Rejectede'"
                    class="cg-badge sm warning">rejected</div>
                <div *ngIf="item?.status === 'accepted' || item?.status === 'Accepted'"
                    class="cg-badge sm success">Accepted</div>
            </td>
              
              <td class="text-center">
                {{ item?.inspection?.date | date: 'MM/dd/yyyy'}}
              </td>
              <td class="text-center">{{moment(item?.inspection?.startTime, 'HH:mm').format('hh:mm A')}}</td>
              <!-- <td>{{item?.inspection?.endTime}}</td> -->
              <!-- <td class="text-center">
                {{ item?.inspection?.status }}
              </td> -->
              <td class="text-center">
                <div *ngIf="item?.inspection?.status === 'canceled' || item?.inspection?.status === 'Canceled'"
                    class="cg-badge sm danger">Canceled</div>
                <div *ngIf="item?.inspection?.status === 'scheduled' || item?.inspection?.status === 'Scheduled'"
                    class="cg-badge sm info">Scheduled</div>

                    <div *ngIf="item?.inspection?.status === 'completed' || item?.inspection?.status === 'Completed'"
                    class="cg-badge sm success">Completed</div>
            </td>
              
              <!-- <td>
                <app-read-more *ngIf="item?.inspection?.description !== null" [text]="item?.inspection?.description"
                  [maxLength]="50"></app-read-more>
              </td> -->
            </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</div>


<app-ipagination [pagination]="pagination" [activePage]="filters.page" (pageChangeEvent)="setPagination($event)"
  *ngIf="dataStatus === 'done'"></app-ipagination>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MentorInspectionsComponent } from './mentor-inspections.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from 'src/app/website/shared/shared.module';
import { AdminSharedModule } from '../../admin-shared/admin-shared.module';
import { DataService } from './data.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AdminSharedModule,
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    ModalModule.forRoot(),
    RouterModule.forChild([
      { path: '', component: MentorInspectionsComponent },
    ])
  ],
  declarations: [MentorInspectionsComponent],
  providers: [DataService]
})
export class MentorInspectionsModule { }
